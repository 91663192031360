import React from "react"
import { graphql, useStaticQuery } from "gatsby"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Img from "gatsby-image"

import pahlenGuide from "../../static/Pahlen Pool & Spa Heat Exchanger Guide_2014 (1).pdf"

const ProductTemplate = ({data,pageContext}) => {
  console.log('Page Context')
  console.log(pageContext)
  console.log(data)
  return(
    <Layout>
      <SEO title="Home" />

      <div className="product">
        <div className="clearfix">
          <h2 className="product"><span className={`icon small product ${data.product.frontmatter.catalog}`}/>{data.catalog.frontmatter.title}</h2>
          <div className="go-back"><a href={`/products/${data.product.frontmatter.catalog}`}>Go Back</a></div>

        </div>

        <div className="product-more-info clearfix">
          <div className="product-image">
            <div className={`icon manufacturer ${data.product.frontmatter.manufacturer}`}/>
            {(data.image)?<Img class={'responsive-image'} fluid={data.image.childImageSharp.fluid}/>:``}

          </div>

          <div className="product-text">
            <div className="product-info">
              <h1>{data.product.frontmatter.title}</h1>
              <p className="manufacturer">{data.manufacturer.frontmatter.title}</p>
              <h2>Description</h2>
              <p className="description">{data.product.frontmatter.description}</p>
              {(data.product.frontmatter.pdf.length && data.pdf !=null) >0?<a href={data.pdf.publicURL} target="_blank"><span className="icon link download black"/>Download PDF</a>:``}
              <p>
              { (data.product.frontmatter.manufacturer === 'pahlen')
              && <a href={pahlenGuide} target="_blank"><span className="icon link download black"/>Pahlen Pool Heat Exchanger Application Guide & Warranty</a>}
              </p>
            </div>
            <div className="product-size" dangerouslySetInnerHTML={ {__html:data.product.html } }>
            </div>
          </div>

        </div>


      </div>

    </Layout>

  )
}


export const query = graphql`
   query ($product: String!, $catalog: String!, $manufacturer: String!, $img: String!, $pdf: String!) {
      product: markdownRemark(fileAbsolutePath:{regex:$product}){
        html
        frontmatter{
          title
          manufacturer
          description
          pdf
          catalog
        }
      }
      
      catalog: markdownRemark(fileAbsolutePath:{regex:$catalog}){
        frontmatter{
          title
     
        }
      }
      
      manufacturer: markdownRemark(fileAbsolutePath:{regex:$manufacturer}){
        frontmatter{
          title
     
        }
      }
      
      image: file(relativePath: {eq: $img}){
        childImageSharp {
          fluid(quality: 100) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      
      pdf: file(relativePath: {eq: $pdf}){
        publicURL
      }
    }
  `


export default ProductTemplate